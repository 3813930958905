@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;500;900&display=swap");

body {
   padding: 0;
   margin: 0;
   font-family: "Noto Sans", sans-serif;
   font-weight: 500;
   font-size: 1rem;
   line-height: 1.2;
   letter-spacing: 0.0075em;
   color: #999;
   overflow: "hidden";
   background-color: transparent !important;
}

.pageBG {
   background-image: linear-gradient(
      135deg,
      #eeeeee 6.25%,
      #ffffff 6.25%,
      #ffffff 50%,
      #eeeeee 50%,
      #e3e3e3 56.25%,
      #ffffff 56.25%,
      #ffffff 100%
   ) !important;
   background-size: 11.31px 11.31px !important;
   box-sizing: "border-box";
}

.transparentBG {
   background-image: linear-gradient(
         45deg,
         #222 25%,
         transparent 25%,
         transparent 75%,
         #222 75%
      ),
      linear-gradient(45deg, #222 25%, #111 25%, #111 75%, #222 75%);
   background-size: 20px 20px;
   background-position: 0px 0px, 10px 10px;
}

a {
   color: #fd0c73;
   text-decoration: none;
   border-bottom: 1px dotted #fd0c73;
}

a:hover {
   color: rgb(55, 53, 47);
   border-bottom: 1px solid rgb(55, 53, 47);
}

.howto {
   padding: 1em 2em;
   border: 1px solid rgba(0, 0, 0, 0.154);
   border-radius: 5px;
   background-color: rgba(0, 0, 0, 0.05);
   height: 100%;
   text-align: center;
}
